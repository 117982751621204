import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    GridColDef,
    GridPreProcessEditCellProps,
    useGridApiRef,
} from '@mui/x-data-grid';
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import TextInputColumn from '../../../../components/EditableTable/CustomColumns/TextInputColumn/TextInputColumn';
import EditableTable from '../../../../components/EditableTable/EditableTable';
import { ExpandedText } from '../../../DataRequest/ExpandedText/ExpandedText';
import Helpers from '../../../../utils/helper';
import {
    ProjectVariableOption,
    ProjectVariable,
} from '../../../../models/DataRequestHub/ProjectVariable';
import moment from 'moment';
import { DataFormType } from '../../../../models/DataRequestHub/DataFormTypeEnum';
import {
    validateDateAndAdjustClasses,
    validateQuestionDisplayOrder,
    validateRequiredCell,
} from '../../../../components/EditableTable/EditableTableValidationHelper';
import DataFormQuestion from '../../../../models/DataRequestHub/DataFormQuestion';
import { TimeZone, TimeZoneHelper } from '../../../../utils/timeZoneHelper';

interface FinancialRequestTemplateEditorInterface {
    setIsProjectVariableEditPopupVisible(value: boolean): void;
    setVariableNameToEdit(value: string): void;
    setVarialbeIdToEdit(value: number): void;
    templateRows: DataFormQuestion[];
    setTemplateRows(rows: DataFormQuestion[]): void;
    setProjectVariableOptionsToEdit(options: ProjectVariableOption[]): void;
    isLoading: boolean;
    likelySourceVariable: ProjectVariable;
    displayFormatVariable: ProjectVariable;
    frequencyVariable: ProjectVariable;
}

const FinancialRequestTemplateEditor = forwardRef(
    (props: FinancialRequestTemplateEditorInterface, ref) => {
        const apiRef = useGridApiRef();
        const {
            setVariableNameToEdit,
            setIsProjectVariableEditPopupVisible,
            templateRows,
            setTemplateRows,
            setVarialbeIdToEdit,
            setProjectVariableOptionsToEdit,
            isLoading,
            likelySourceVariable,
            displayFormatVariable,
            frequencyVariable,
        } = props;

        const gridTableRef = useRef(null);
        const [timeZone] = useState<TimeZone>(
            TimeZoneHelper.getTimeZoneByDate(new Date())
        );
        useImperativeHandle(ref, () => ({
            addRow() {
                gridTableRef.current.addRow();
            },
            validateAllRows() {
                return validateAllRows();
            },
        }));

        const alignFinancialRequestQuestionPeriod = (
            firstDate: Date,
            secondDate: Date
        ): { dateStarted: Date; dateEnded: Date } => {
            if (firstDate > secondDate) {
                return {
                    dateStarted: new Date(secondDate),
                    dateEnded: new Date(firstDate),
                };
            }
            return {
                dateStarted: new Date(firstDate),
                dateEnded: new Date(secondDate),
            };
        };

        const columns = (): GridColDef[] => [
            {
                field: 'displayOrder',
                headerName: 'Display Order',
                minWidth: 140,
                flex: 0.25,
                type: 'number',
                cellClassName: 'cell-input display-order',
                editable: true,
                align: 'left',
                headerAlign: 'left',
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    const value = params.props.value;
                    const hasError = value <= 0;
                    return { ...params.props, error: hasError };
                },
            },
            {
                field: 'displayName',
                headerName: 'Item Name',
                type: 'string',
                cellClassName: 'cell-text-input display-name',
                minWidth: 150,
                flex: 1,
                editable: true,
                renderCell: (params) => (
                    <ExpandedText
                        content={params.row.displayName}
                        uniqueId={`displayName-input-${params?.id ?? 0}`}
                        heightToShowLinksPx={95}
                        className="MuiDataGrid-cellContent"
                    />
                ),
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    const value = params.props.value;

                    const isItemNameAlreadyUsed = templateRows
                        .filter(
                            (f) => f.id !== params.id && f.gridId !== params.id
                        )
                        .some(
                            (s) =>
                                s.displayName?.toLocaleLowerCase()?.trim() ===
                                value?.toLocaleLowerCase()?.trim()
                        );

                    if (isItemNameAlreadyUsed) {
                        return { ...params.props, error: true };
                    }

                    const hasError = value?.trim().length <= 0;
                    return { ...params.props, error: hasError };
                },
                renderEditCell: (params) => <TextInputColumn {...params} />,
            },
            {
                field: 'description',
                headerName: 'Description',
                minWidth: 150,
                flex: 1,
                type: 'string',
                cellClassName: 'cell-text-input description',
                editable: true,
                renderCell: (params) => (
                    <ExpandedText
                        content={params.row.description}
                        uniqueId={`description-input-${params?.id ?? 0}`}
                        heightToShowLinksPx={95}
                    />
                ),
                renderEditCell: (params) => <TextInputColumn {...params} />,
            },
            {
                field: 'frequency',
                headerName: 'Frequency',
                minWidth: 170,
                cellClassName: 'frequency',
                flex: 0.5,
                editable: true,
                type: 'singleSelect',
                valueOptions: frequencyVariable?.options.map((x) => x.option),
                renderHeader: () => {
                    return (
                        <>
                            Frequency
                            <FontAwesomeIcon
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setIsProjectVariableEditPopupVisible(true);
                                    setVarialbeIdToEdit(frequencyVariable.id);
                                    setVariableNameToEdit(
                                        frequencyVariable.name
                                    );
                                    setProjectVariableOptionsToEdit(
                                        frequencyVariable.options
                                    );
                                }}
                                style={{ marginLeft: '10px' }}
                                size="1x"
                                icon={faEdit as any}
                                title="Save"
                            />
                        </>
                    );
                },
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    const hasError = params.props.value?.length <= 0;
                    return { ...params.props, error: hasError };
                },
            },
            {
                field: 'likelySource',
                headerName: 'Likely Source',
                minWidth: 170,
                cellClassName: 'likely-source',
                flex: 0.5,
                type: 'singleSelect',
                renderHeader: () => {
                    return (
                        <>
                            Likely Source
                            <FontAwesomeIcon
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setIsProjectVariableEditPopupVisible(true);
                                    setVarialbeIdToEdit(
                                        likelySourceVariable.id
                                    );
                                    setVariableNameToEdit(
                                        likelySourceVariable.name
                                    );
                                    setProjectVariableOptionsToEdit(
                                        likelySourceVariable.options
                                    );
                                }}
                                style={{ marginLeft: '10px' }}
                                size="1x"
                                icon={faEdit as any}
                                title="Save"
                            />
                        </>
                    );
                },
                valueOptions: likelySourceVariable?.options.map(
                    (x) => x.option
                ),
                editable: true,
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    const hasError = params.props.value?.length <= 0;
                    return { ...params.props, error: hasError };
                },
            },
            {
                field: 'displayFileFormat',
                headerName: 'Display Format',
                cellClassName: 'display-file-format',
                minWidth: 170,
                flex: 0.5,
                type: 'singleSelect',
                renderHeader: () => {
                    return (
                        <>
                            Display Format
                            <FontAwesomeIcon
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setIsProjectVariableEditPopupVisible(true);
                                    setVarialbeIdToEdit(
                                        displayFormatVariable.id
                                    );
                                    setVariableNameToEdit(
                                        displayFormatVariable.name
                                    );
                                    setProjectVariableOptionsToEdit(
                                        displayFormatVariable.options
                                    );
                                }}
                                style={{ marginLeft: '10px' }}
                                size="1x"
                                icon={faEdit as any}
                                title="Save"
                            />
                        </>
                    );
                },
                valueOptions: displayFormatVariable?.options.map((x) => x.name),
                editable: true,
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    const hasError = params.props.value?.length <= 0;

                    return { ...params.props, error: hasError };
                },
            },
            {
                field: 'allowedFileFormat',
                editable: true,
            },
            {
                field: 'datePeriodStarted',
                headerName: 'Start',
                minWidth: 150,
                flex: 0.5,
                cellClassName: 'period-date-start date-picker',
                editable: true,
                type: 'date',
                valueFormatter: (params: any) =>
                    params?.value ? moment(params?.value).format('L') : null,
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    const hasError = !!!params.props.value;
                    return { ...params.props, error: hasError };
                },
                valueSetter: (params) => {
                    const { row, value } = params;
                    const valueWithTImeZoneOffset = TimeZoneHelper.parseUtcDate(
                        new Date(value),
                        timeZone
                    );

                    if (!!valueWithTImeZoneOffset && !!row.datePeriodEnded) {
                        const { dateStarted, dateEnded } =
                            alignFinancialRequestQuestionPeriod(
                                valueWithTImeZoneOffset,
                                row.datePeriodEnded
                            );

                        return {
                            ...params.row,
                            datePeriodStarted: dateStarted,
                            datePeriodEnded: dateEnded,
                        };
                    }

                    return {
                        ...row,
                        datePeriodStarted: valueWithTImeZoneOffset,
                    };
                },
            },
            {
                field: 'datePeriodEnded',
                headerName: 'End',
                minWidth: 150,
                flex: 0.5,
                cellClassName: 'period-date-end date-picker',
                editable: true,
                type: 'date',
                valueFormatter: (params: any) =>
                    params?.value ? moment(params?.value).format('L') : null,
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    const hasError = !!!params.props.value;
                    return { ...params.props, error: hasError };
                },
                valueSetter: (params) => {
                    const { row, value } = params;
                    const valueWithTImeZoneOffset = TimeZoneHelper.parseUtcDate(
                        new Date(value),
                        timeZone
                    );

                    if (!!row.datePeriodStarted && !!valueWithTImeZoneOffset) {
                        const { dateStarted, dateEnded } =
                            alignFinancialRequestQuestionPeriod(
                                valueWithTImeZoneOffset,
                                row.datePeriodStarted
                            );

                        return {
                            ...params.row,
                            datePeriodStarted: dateStarted,
                            datePeriodEnded: dateEnded,
                        };
                    }

                    return { ...row, datePeriodEnded: value };
                },
            },
            {
                field: 'storageFolder',
                headerName: 'Storage Folder',
                minWidth: 150,
                flex: 0.5,
                cellClassName: 'cell-text-input storage-folder',
                editable: true,
                renderCell: (params) => (
                    <ExpandedText
                        content={params.row.storageFolder}
                        uniqueId={`storageFolder-input-${params?.id ?? 0}`}
                        heightToShowLinksPx={95}
                    />
                ),
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    if (params.hasChanged) {
                        const value = params.props.value as string;
                        const hasError = Helpers.hasFoldersPathError(
                            value,
                            '/'
                        );

                        return { ...params.props, error: hasError };
                    }

                    return { ...params.props };
                },
                renderEditCell: (params) => <TextInputColumn {...params} />,
            },
        ];

        const validateAllRows = () => {
            let isValid = true;
            for (let index = 0; index < templateRows.length; index++) {
                const rowId =
                    templateRows[index]?.id ?? templateRows[index].gridId;
                const element = document.querySelector(
                    '[data-id="' + rowId + '"]'
                );

                if (element) {
                    const question = templateRows[index];
                    const hasDisplayNameError = validateRequiredCell(
                        element,
                        '.display-name',
                        question.displayName
                    );

                    const hasDisplayFileFormatError = validateRequiredCell(
                        element,
                        '.display-file-format',
                        question.displayFileFormat
                    );

                    const hasAllowedFormatError = validateRequiredCell(
                        element,
                        '.allowed-file-format',
                        question.allowedFileFormat
                    );

                    const hasLikelySourceError = validateRequiredCell(
                        element,
                        '.likely-source',
                        question.likelySource
                    );

                    const hasFrequencyError = validateRequiredCell(
                        element,
                        '.frequency',
                        question.frequency
                    );
                    const hasDatePeriodStartedError =
                        validateRequiredCell(
                            element,
                            '.period-date-start',
                            question.datePeriodStarted
                        ) ||
                        validateDateAndAdjustClasses(
                            element,
                            '.period-date-start',
                            question.datePeriodStarted
                        );

                    const hasDatePeriodEndedError =
                        validateRequiredCell(
                            element,
                            '.period-date-end',
                            question.datePeriodEnded
                        ) ||
                        validateDateAndAdjustClasses(
                            element,
                            '.period-date-end',
                            question.datePeriodEnded
                        );

                    if (
                        hasDisplayNameError ||
                        hasDisplayFileFormatError ||
                        hasAllowedFormatError ||
                        hasLikelySourceError ||
                        hasFrequencyError ||
                        hasDatePeriodStartedError ||
                        hasDatePeriodEndedError
                    ) {
                        isValid = false;
                    }
                }
            }
            if (
                !validateQuestionDisplayOrder(
                    templateRows,
                    apiRef.current.state,
                    DataFormType.FinancialRequest
                )
            ) {
                return false;
            }
            return isValid;
        };

        const mapAllowedFilesFormat = (fileFormat: string) =>
            displayFormatVariable?.options?.find((f) => f.name === fileFormat)
                ?.option ?? 'any';

        const updateRows = (
            newRows: (oldRows: DataFormQuestion[]) => DataFormQuestion[]
        ): void => {
            const result = newRows(templateRows).map((question) => ({
                ...question,
                allowedFileFormat: mapAllowedFilesFormat(
                    question.displayFileFormat
                ),
            }));

            setTemplateRows(result);
        };

        useEffect(() => {
            validateAllRows();
        }, [templateRows]);

        return (
            <EditableTable
                dataFormType={DataFormType.FinancialRequest}
                columns={columns()}
                rows={templateRows}
                setRows={updateRows}
                isLoading={isLoading}
                fieldToFocus="displayOrder"
                ref={gridTableRef}
                gridApiRef={apiRef}
            />
        );
    }
);

export default FinancialRequestTemplateEditor;
