import React, { useState, useEffect, Fragment } from 'react';
import {
    Button,
    ModalFooter,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    ModalHeader,
    ModalBody,
    Label,
    Form,
    FormGroup,
    Col,
    Row,
    Table,
    Card,
    CardHeader,
    CardBody,
} from 'reactstrap';
import classnames from 'classnames';
import BootstrapTable from '../../../../../components/react-bootstrap-table-next/react-bootstrap-table2';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createNotification from '../../../../../utils/createNotification';
import ChatExample from '../../../../../components/Layout/AppHeader/Components/TabsContent/TimelineExample';
import CustomModal, { MODAL_SIZE } from '../../../../../components/Modal/Modal';
import jwtDecode from 'jwt-decode';
import PracticeProfileFields from '../../../../Practices/PracticeProfileFields';
import './UserDetailsModal.scss';
import { AssociateMembersTable } from '../../../../Associates/AssociateMembersTable';
import { USER_ROLES } from '../../../../../utils/constants';
import W9HistoryModal from '../../../../../components/W9Approval/W9HistoryModal';
import Rodal from '../../../../../components/Rodal/Rodal';
import MyWallet from '../../../../UserPages/Profile/MyWallet/MyWallet';
import RewardsHistoryPaginatedTable from '../../../../UserPages/Profile/RewardsHistoryPaginatedTable/RewardsHistoryPaginatedTable';
import PurchaseHistoryPaginatedTable from '../../../../UserPages/Profile/PurchaseHistoryPaginatedTable/PurchaseHistoryPaginatedTable';
import SubscriptionsHost from '../../../../SubscriptionsHost/SubscriptionsHost';
import Loader from 'react-loaders';
import moment from 'moment';
import { W9DocumentStatus } from '../../../../../models/W9Document/W9DocumentStatus';
import { useStateSelector } from '../../../../../store/selectors';
import { Tooltip } from 'react-tooltip';
import ConfirmationModal from '../../../../../components/Modal/ConfirmationModal';
import { OwnerTypeEnum } from '../../../../../wallet/models/OwnerTypeEnum';
import UserDocumentsTable from '../../../../../components/UserDocumentsTable/UserDocumentsTable';
import DonationHistory from '../../../../../components/PaymentHistory/DonationHistory';
import UserBasicDetails from './UserBasicDetails';
import MyReferralsAndInvitees from '../../../../Referal/ReferralsAndInvitees/ReferralsAndInvitees';
import { DiscountCodeTypes } from '../../../../Referal/Model/Referral/DiscountCodeType';
import { WalletOwnerType } from '../../../../Referal/Model/Referral/DiscountCode';

const customStyles = { marginTop: '23px' };

const UserDetailsModal = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const axios = useStateSelector((state) => state.core.axios);
    const [userData, setUserData] = useState({});
    const [isUserDataLoading, setIsUserDataLoading] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [regPractise, setRegPractise] = useState([]);
    const [referrarName, setReferrarName] = useState('');
    const [referralDetails, setReferralDetails] = useState({});
    const [isReferralPartner, setIsReferralPartner] = useState(false);
    const [changeLogData, setChangeLogData] = useState([]);
    const [rowToDelete, setRowToDelete] = useState(null);
    const [rowToBlock, setRowToBlock] = useState(null);
    const [userDocList, setUserDocList] = useState([]);
    const [selectedTransactionId, setSelectedTransactionId] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] =
        useState(false);
    const [practiceIdToConvertToFormer, setPracticeIdToConvertToFormer] =
        useState(false);
    const [isNonprofitAdmin, setIsNonprofitAdmin] = useState(false);
    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const deleteUserHandler = () => {
        let row = {
            email: userData.email,
            phone: userData.phone,
            id: userData.id,
            username: '',
        };
        setRowToDelete(row);
    };

    const cancelDelete = () => {
        if (!isDeleting) {
            setRowToDelete(null);
        }
    };

    const blockUserHandler = () => {
        let row = {
            email: userData.email,
            phone: userData.phone,
            id: userData.id,
            username: '',
        };
        setRowToBlock(row);
    };

    const cancelBlock = () => {
        setRowToBlock(null);
    };

    const convertPracticeToFormer = () => {
        axios
            .put(
                `/api/Practice/ConvertToFormer/${practiceIdToConvertToFormer}/${props.userID}`
            )
            .then(() => {
                fetchUsersDetailsData(props.userID);
            })
            .catch(function (error) {
                createNotification(error, 'error');
            });
    };

    const columns = [
        {
            dataField: 'name',
            text: 'Practice Name',
        },
        {
            dataField: 'isFormer',
            text: 'Practice Status',
            formatter: (cellContent, row) => {
                return cellContent ? (
                    <>Former Practice</>
                ) : (
                    <>
                        Current Practice{' '}
                        <FontAwesomeIcon
                            id={'info' + row.id}
                            onClick={() => {
                                setIsConfirmationDialogVisible(true);
                                setPracticeIdToConvertToFormer(row.id);
                            }}
                            icon={faClockRotateLeft}></FontAwesomeIcon>{' '}
                        <Tooltip
                            anchorId={'info' + row.id}
                            className="tooltip-content"
                            id="event"
                            place="right">
                            <div>Make Emeritus Practice</div>
                        </Tooltip>
                    </>
                );
            },
        },
        {
            dataField: 'practiceOwnerEmail',
            text: 'Practice Owner Email',
        },
        {
            dataField: 'registrationDate',
            text: 'Registered Date',
            formatter: (cellContent) =>
                cellContent
                    ? new Date(cellContent).toLocaleString('en-US')
                    : '',
        },
    ];

    const [docListColumns, setDocListColumns] = useState([
        {
            dataField: 'submittedOn',
            text: 'Upload Date',
            formatter: (cellContent, row) =>
                row ? moment(cellContent).format('L') : '',
        },
        {
            dataField: 'taxYear',
            text: 'Tax year',
            formatter: (cellContent) => cellContent ?? '',
        },
        {
            dataField: 'status',
            text: 'Status',
            formatter: (cellContent, row) =>
                cellContent === W9DocumentStatus.ACCEPTED
                    ? 'Approved'
                    : 'Submitted',
        },
        {
            dataField: 'approver',
            text: 'Approver',
        },
        {
            dataField: 'fileName',
            text: 'PDF Copy',
            formatter: (_cellContent, row) => {
                return (
                    <>
                        <Button
                            className="btn btn-secondary"
                            color="secondary"
                            onClick={() => getDocument(row)}>
                            PDF
                        </Button>
                    </>
                );
            },
        },
    ]);

    const getDocument = (row) => {
        let postObj = {
            DocTypeId: 4,
            Id: row.id,
        };
        axios
            .post('/api/Documents/GetDocumentUrl', postObj)
            .then((response) => {
                if (response.isError) {
                    createNotification(
                        'An error occured while saving the details',
                        'error'
                    );
                } else {
                    let link = response.data.data;
                    window.open(link, '_blank');
                }
            })
            .catch(function (error) {
                createNotification(error, 'error');
            });
    };

    const expandRow = {
        renderer: (row) => (
            <div>
                {row ? (
                    <ModalBody className="practice-profile">
                        <Row>
                            <Col md={4}>{pmgAffiliation(row)}</Col>
                            <Col md={2}>
                                <Label for="associateMember">
                                    Affiliated Members
                                </Label>
                            </Col>
                            <Col md={6}>{memberList(row)}</Col>
                        </Row>
                        <PracticeProfileFields
                            practice={row}
                            isEditMode={false}
                            isShortView={true}></PracticeProfileFields>
                    </ModalBody>
                ) : (
                    ''
                )}
                {row.reportsList.length > 0 ? reportList(row.reportsList) : ''}
            </div>
        ),
        showExpandColumn: true,
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            if (isAnyExpands) {
                return <b>-</b>;
            }
            return <b>+</b>;
        },
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return <b style={{ cursor: 'pointer' }}>-</b>;
            }
            return <b style={{ cursor: 'pointer' }}>+</b>;
        },
    };

    const pmgAffiliation = (row) => {
        return (
            <FormGroup>
                <Label for="pmgAffiliation">PMG Affiliation</Label>
                <h6>
                    {row.groupAffiliation.groupName
                        ? row.groupAffiliation.groupName
                        : 'NA'}
                </h6>
            </FormGroup>
        );
    };

    const suspendUser = () => {
        axios
            .post('/api/Users/SuspendUser?userId=' + userData.id)
            .then((result) => {
                if (result && result.data && result.data.data) {
                    setUserData(result.data.data);
                }
            });
    };

    const memberList = (row) => {
        const members =
            row.associateMembers.length > 0 ? (
                <FormGroup>
                    {row.associateMembers.map((member, index) => (
                        <p key={index}>
                            <h6>
                                {member.memberName} – {member.role}
                            </h6>
                        </p>
                    ))}
                </FormGroup>
            ) : (
                <h6>NA</h6>
            );
        return members;
    };
    const reportList = (data) => {
        return (
            <Table className="rep">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Type</th>
                        <th>Status</th>
                        <th>Created On</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((val) => (
                        <tr>
                            <td>{val.reportID}</td>
                            <td>{val.reportType}</td>
                            <td>{val.status}</td>
                            <td>
                                {val.createdOn
                                    ? new Date(
                                          val.createdOn
                                      ).toLocaleDateString('en-US')
                                    : ''}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    };

    const documentsDetails = () => {
        return (
            <UserDocumentsTable
                documents={documents}
                userId={props.userID}
                refrechDocumentsList={() => fetchUsersDetailsData(props.userID)}
            />
        );
    };

    const practiseDetails = () => {
        return (
            <BootstrapTable
                keyField="practiceID"
                data={regPractise}
                columns={columns}
                expandRow={expandRow}
            />
        );
    };

    const renderNonprofitIcon = (discountCode) => (
        <>
            <svg
                className="nonprofit-icon"
                data-tooltip-html="Nonprofit Discount Code"
                id={`nonprofit-discount-code-${discountCode.code}`}
                xmlns="http://www.w3.org/2000/svg"
                height="62"
                viewBox="0 -960 960 960"
                width="62">
                <path
                    fill="#535bd6"
                    d="M630.615-472.154 480.462-615.231q-25.354-24.389-42.293-54.594-16.938-30.206-16.938-65.79 0-42.744 29.295-73.565Q479.821-840 522.462-840q33.23 0 60.461 19.423t47.692 46.192q20.462-26.769 47.308-46.192Q704.769-840 738.769-840q42.641 0 71.936 30.82Q840-778.359 840-735.615q0 35.488-17.481 65.611Q805.038-639.88 780-615.231L630.615-472.154Zm0-41.462 124.874-120.813q21.398-20.348 37.57-45.507 16.172-25.158 16.172-55.679 0-30.308-20.178-51.962-20.177-21.654-50.284-21.654-27.238 0-48.311 16.769-21.073 16.77-37.919 38.923l-21.924 28.847-22.692-28.847q-16.077-22.153-37.15-38.923-21.073-16.769-48.311-16.769-30.107 0-50.284 21.654Q452-765.923 452-735.615q0 30.521 15.787 55.679 15.788 25.159 38.213 45.782l124.615 120.538ZM245-204.615 560.462-110l244.153-75.692q1.539-23.846-11.12-35.039-12.659-11.192-29.341-11.192h-192.94q-19.753 0-40.253-2.115-20.5-2.116-40.884-9.424L401-270.923l12.077-31.77 87.077 32q15.615 6.308 35.192 7.539 19.577 1.231 46.731.461h26q0-23.307-10.923-37.269-10.923-13.961-27.846-20.423l-222.616-83-4.615-1.154q-1.539-.384-3.846-.384H245v200.308ZM96.154-120v-315.693h240.172q5.469 0 10.11 1.154 4.641 1.154 9.487 2.077l223.385 82.769q24.154 8.616 42.192 30.385 18.039 21.769 18.039 56.615h124.615q36.667 0 56.256 24.693Q840-213.308 840-180v18.308L563.923-77.231 245-171.615V-120H96.154Zm30.769-30.769h86.308v-254.154h-86.308v254.154Zm503.692-573.923Z"
                />
            </svg>
            <Tooltip
                place={'top'}
                className={'tooltip-content place-top'}
                anchorId={`nonprofit-discount-code-${discountCode.code}`}
                classNameArrow="arrow"
                offset={10}
            />
        </>
    );

    const referralData = () => {
        return (
            <div className="user-details-referral-data">
                {referrarName != '' ? (
                    <Form>
                        <FormGroup row>
                            <Label for="name" sm={4}>
                                Referred By:
                            </Label>
                            <Label for="name" sm={8}>
                                {referrarName}
                            </Label>
                        </FormGroup>
                    </Form>
                ) : (
                    ''
                )}
                {isReferralPartner ? (
                    <>
                        <Form>
                            <FormGroup row>
                                <Label for="name" sm={4}>
                                    Referrer Code:
                                </Label>
                                <Col sm={8} className="center-container">
                                    <div>
                                        <Label for="name">
                                            {referralDetails.referralCode}
                                        </Label>
                                    </div>
                                    {referralDetails.subCodes
                                        .filter((s) => s.isPrimary)
                                        .map((s) => (
                                            <div>
                                                <Label for="name">
                                                    {s.code}
                                                    {renderNonprofitIcon(s)}
                                                </Label>
                                            </div>
                                        ))}
                                </Col>
                            </FormGroup>
                            {referralDetails.subCodes.filter(
                                (s) => !s.isPrimary
                            )?.length > 0 ? (
                                <div>
                                    <FormGroup row>
                                        <Label for="name" sm={4}>
                                            Sub-Codes:
                                        </Label>

                                        <Col sm={8}>
                                            {referralDetails.subCodes
                                                .filter((s) => !s.isPrimary)
                                                .map((s) => (
                                                    <Row className="mb-2 sub-code-row">
                                                        <Col sm={3}>
                                                            <Label className="mb-0">
                                                                {s.code}
                                                                {s.ownerWalletType ===
                                                                    WalletOwnerType.Charity &&
                                                                referralDetails.subCodes.some(
                                                                    (sc) =>
                                                                        sc.ownerWalletType !==
                                                                        WalletOwnerType.Charity
                                                                )
                                                                    ? renderNonprofitIcon(
                                                                          s
                                                                      )
                                                                    : []}
                                                            </Label>
                                                        </Col>
                                                        <Col sm={6}>
                                                            <Label className="mb-0">
                                                                {
                                                                    DiscountCodeTypes.find(
                                                                        (t) =>
                                                                            t.id ==
                                                                            s.codeType
                                                                    ).name
                                                                }
                                                            </Label>
                                                        </Col>
                                                    </Row>
                                                ))}
                                        </Col>
                                    </FormGroup>
                                </div>
                            ) : (
                                []
                            )}
                        </Form>
                        <MyReferralsAndInvitees
                            referredPartyEmail=""
                            primaryCode={referralDetails.referralCode}
                            hasSubCodes={referralDetails.subCodes?.length > 0}
                            userId={props.userID}></MyReferralsAndInvitees>
                    </>
                ) : (
                    ''
                )}
            </div>
        );
    };

    useEffect(() => {
        document.body.style.overflow = 'hidden';
    }, []);

    useEffect(() => {
        fetchUsersDetailsData(props.userID);
        fetchChangeLoggData(props.userID);

        const filterApprovedByUserId = {
            requestModel: {
                Filter: {
                    Filters: [
                        {
                            Field: 'userId',
                            Operator: 'eq',
                            Value: props.userID,
                        },
                    ],
                    Logic: '',
                },
                PageNumber: 1,
                PageSize: 5,
                Sort: {
                    Dir: '',
                    Field: '',
                },
                Type: 1,
            },
            userId: props.userID,
        };
        fetchW9ApprovedDocumentList(filterApprovedByUserId);

        let userId;
        let token = localStorage.getItem('token');
        if (token) {
            let decodedToken = jwtDecode(token);
            if (decodedToken) {
                if (decodedToken['custom:id']) {
                    userId = decodedToken['custom:id'];
                }
            }
        }
    }, [props]);

    // API CALLS

    const fetchUsersDetailsData = (userID) => {
        setIsUserDataLoading(true);

        return axios
            .get('/api/Users/GetUserDetails?userId=' + userID)
            .then((response) => {
                if (response && response.isError) {
                    console.log(response.message);
                } else {
                    let userD = response.data.data;
                    userD.practices.forEach((practice) => {
                        practice.id = practice.practiceID;
                    });
                    setUserData(userD);
                    let filterDocuments = userD.documents.filter(
                        (x) => x.timeOfUpload != null
                    );
                    setDocuments(filterDocuments);
                    setRegPractise(userD.practices);
                    setReferrarName(userD.referrerName);

                    if (userD.isReferralPartner) {
                        setIsReferralPartner(
                            userD.isReferralPartner &&
                                userD.referrarDetails?.isReferralAgreementSigned
                        );
                        if (userD.referrarDetails != null) {
                            setReferralDetails(userD.referrarDetails);
                        }
                    }
                    setIsNonprofitAdmin(
                        userD.roleId === USER_ROLES.CHARITY_ADMIN
                    );
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setIsUserDataLoading(false);
            });
    };

    const fetchChangeLoggData = (userID) => {
        axios
            .get('/api/EventLog/GetAllLogs?userId=' + userID)
            .then((response) => {
                if (response && response.isError) {
                    console.log(response.message);
                } else {
                    let logData = response.data.data;

                    let data = [];
                    let logText = '';
                    let logDate = '';
                    let badgeColor = '';

                    logData.forEach((element) => {
                        if (element.eventId === 1) {
                            logText = 'User Registered';
                            badgeColor =
                                'badge badge-dot badge-dot-xl badge-success';
                        } else if (element.eventId === 2) {
                            logText = 'Practice Registration Done';
                            badgeColor =
                                'badge badge-dot badge-dot-xl badge-warning';
                        } else if (element.eventId === 3) {
                            logText = 'NDA Signing Done';
                            badgeColor =
                                'badge badge-dot badge-dot-xl badge-danger';
                        } else if (element.eventId === 4) {
                            logText = 'Referral Partner Registartion Done';
                            badgeColor =
                                'badge badge-dot badge-dot-xl badge-primary';
                        } else if (element.eventId === 5) {
                            logText = 'Referral Partner Agreement Signing Done';
                            badgeColor =
                                'badge badge-dot badge-dot-xl badge-success';
                        } else if (element.eventId === 6) {
                            logText = 'Referral Invitations Done';
                            badgeColor =
                                'badge badge-dot badge-dot-xl badge-warning';
                        } else if (element.eventId === 7) {
                            logText = 'Password Reset Done';
                            badgeColor =
                                'badge badge-dot badge-dot-xl badge-danger';
                        } else if (element.eventId === 8) {
                            logText = 'Account Details Updation';
                            badgeColor =
                                'badge badge-dot badge-dot-xl badge-primary';
                        }

                        logDate = new Date(
                            element.createdOn
                        ).toLocaleDateString('en-US');

                        data.push({
                            text: logText,
                            date: logDate,
                            badgeColor: badgeColor,
                        });
                    });

                    setChangeLogData(data);
                }
            })
            .catch((err) => console.log(err));
    };

    const blockUser = (row) => {
        axios
            .post('/api/Users/BlockUser', {
                email: row.email,
                phone: row.phone,
                userID: row.id,
            })
            .then((response) => {
                if (response && response.data && !response.data.isError) {
                    createNotification('User blocked successfully!', 'success');
                } else {
                    createNotification(
                        'User could not be blocked. Please try again later or contact the Administrator for help',
                        'error'
                    );
                }
                cancelBlock();
                window.location.reload();
            })
            .catch((err) => {
                createNotification(
                    'User could not be blocked. Please try again later or contact the Administrator for help',
                    'error'
                );
                console.log({ err });
                cancelBlock();
            });
    };

    const disableUser = (row) => {
        setIsDeleting(true);

        axios
            .post('/api/Users/InvalidateUser', {
                email: row.email,
                phone: row.phone,
                userID: row.id,
                username: row.username,
            })
            .then((response) => {
                if (response.status === 200) {
                    createNotification('User removed successfully!', 'success');
                    window.location.reload();
                } else {
                    createNotification(
                        'User could not be removed. Something went wrong',
                        'error'
                    );
                }
                cancelDelete();
                setIsDeleting(false);
            })
            .catch((err) => {
                createNotification(
                    'User could not be removed. Please try again later or contact the Administrator for help',
                    'error'
                );
                console.log({ err });
                cancelDelete();
            });
    };

    const forgotPasswordHandler = () => {
        axios
            .post('api/auth/AdminSetUserPassword', {
                username: userData.email,
                password: null,
            })
            .then((response) => {
                if (
                    response &&
                    response.response &&
                    response.response.data &&
                    response.response.data.isError
                ) {
                    createNotification(response.response.data.message, 'error');
                } else {
                    createNotification(
                        'Password reset request generated sucessfully. Please enter the verification code and new password.',
                        'success'
                    );
                }
            })
            .catch((error) => {
                createNotification(error.message, 'error');
            });
    };

    const fetchW9ApprovedDocumentList = (postObj) => {
        axios
            .post('/api/Documents/GetW9ScheduleReferralDocuments', postObj)
            .then((response) => {
                if (response.isError) {
                    createNotification(
                        'An error occured while fetching the details',
                        'error'
                    );
                } else {
                    const documentList = response.data.data.list;
                    setUserDocList(documentList);
                }
            })
            .catch(function (error) {
                createNotification(error, 'error');
            });
    };

    const isUserAbleToHaveMembers = (roleId) => {
        if (
            roleId != USER_ROLES.PRACTICE_SECONDARY_USER &&
            roleId != USER_ROLES.USER
        ) {
            return true;
        } else {
            return false;
        }
    };

    const renderLoader = () => {
        return (
            <div
                className="loader-container"
                style={{ width: '65vw', height: '10vw' }}>
                <div className="loader-container-inner">
                    <div className="text-center">
                        <Loader
                            active={isUserDataLoading}
                            type="ball-scale-multiple"
                        />
                    </div>
                    <h6 className="mt-5">Loading your data...</h6>
                </div>
            </div>
        );
    };

    return (
        <Fragment>
            <Rodal
                width={1285}
                height={1000}
                visible={props.isVisisble}
                animation={'slideRight'}
                showCloseButton={true}
                customStyles={customStyles}
                className={'cls-user-details-modal'}
                onClose={() => {
                    document.body.style.overflow = 'unset';
                    props.onClose();
                }}
                showMask={false}>
                <ModalHeader>Member Details</ModalHeader>
                <ModalBody>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: activeTab === '1',
                                })}
                                onClick={() => {
                                    toggle('1');
                                }}>
                                Basic Details
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: activeTab === '2',
                                })}
                                onClick={() => {
                                    toggle('2');
                                }}>
                                Documents
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: activeTab === '3',
                                })}
                                onClick={() => {
                                    toggle('3');
                                }}>
                                Subscriptions
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: activeTab === '4',
                                })}
                                onClick={() => {
                                    toggle('4');
                                }}>
                                Referral Info
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: activeTab === '5',
                                })}
                                onClick={() => {
                                    toggle('5');
                                }}>
                                Registered Practice
                            </NavLink>
                        </NavItem>
                        {userData &&
                        isUserAbleToHaveMembers(userData.roleId) ? (
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: activeTab === '6',
                                    })}
                                    onClick={() => {
                                        toggle('6');
                                    }}>
                                    Affiliated Members
                                </NavLink>
                            </NavItem>
                        ) : (
                            ''
                        )}
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: activeTab === '7',
                                })}
                                onClick={() => {
                                    toggle('7');
                                }}>
                                Transaction History
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: activeTab === '8',
                                })}
                                onClick={() => {
                                    toggle('8');
                                }}>
                                Change Log
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: activeTab === '9',
                                })}
                                onClick={() => {
                                    toggle('9');
                                }}>
                                Activites on User profile
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: activeTab === '10',
                                })}
                                onClick={() => {
                                    toggle('10');
                                }}>
                                W-9
                            </NavLink>
                        </NavItem>
                    </Nav>
                    {isUserDataLoading ? (
                        renderLoader()
                    ) : (
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1" className="user-info-tab">
                                {userData ? (
                                    <UserBasicDetails userData={userData} />
                                ) : (
                                    ''
                                )}
                            </TabPane>
                            <TabPane tabId="2">{documentsDetails()}</TabPane>
                            <TabPane tabId="3">
                                <SubscriptionsHost
                                    preselectedMemberId={props.userID}
                                />
                            </TabPane>
                            <TabPane tabId="4">{referralData()}</TabPane>
                            <TabPane tabId="5">
                                {regPractise.length > 0 ? (
                                    practiseDetails()
                                ) : (
                                    <div className="empty-msg">
                                        No Data found
                                    </div>
                                )}
                            </TabPane>
                            <TabPane
                                tabId="6"
                                className="associate-member-details">
                                {userData.id ? (
                                    <AssociateMembersTable
                                        currentUserId={userData.id}
                                        summaryData={userData}
                                        isShortView={true}
                                        getSummaryData={
                                            fetchUsersDetailsData
                                        }></AssociateMembersTable>
                                ) : (
                                    ''
                                )}
                            </TabPane>
                            <TabPane tabId="7" className="wallet-tables">
                                <Card className="table-section mb-3">
                                    <CardHeader>
                                        <span className="practice-heading">
                                            {'Wallet'}
                                        </span>
                                    </CardHeader>
                                    <CardBody>
                                        <MyWallet
                                            ownerType={OwnerTypeEnum.User}
                                            selectedTransactionId={
                                                selectedTransactionId
                                            }
                                            onTransactionIdClick={
                                                setSelectedTransactionId
                                            }
                                            isAdminAccess={true}
                                            walletId={props.userID}></MyWallet>
                                    </CardBody>
                                </Card>

                                <Card className="table-section mb-3">
                                    <CardHeader>
                                        <span className="practice-heading">
                                            {'Rewards History'}
                                        </span>
                                    </CardHeader>
                                    <CardBody>
                                        <RewardsHistoryPaginatedTable
                                            selectedTransactionId={
                                                selectedTransactionId
                                            }
                                            onTransactionIdClick={
                                                setSelectedTransactionId
                                            }
                                            isAdminAccess={true}
                                            userId={
                                                props.userID
                                            }></RewardsHistoryPaginatedTable>
                                    </CardBody>
                                </Card>

                                {isNonprofitAdmin ? (
                                    []
                                ) : (
                                    <>
                                        <Card className="table-section mb-3">
                                            <CardHeader>
                                                <span className="practice-heading">
                                                    {'Purchase History'}
                                                </span>
                                            </CardHeader>
                                            <CardBody>
                                                <PurchaseHistoryPaginatedTable
                                                    selectedTransactionId={
                                                        selectedTransactionId
                                                    }
                                                    onTransactionIdClick={
                                                        setSelectedTransactionId
                                                    }
                                                    isAdminAccess={true}
                                                    walletId={
                                                        props.userID
                                                    }></PurchaseHistoryPaginatedTable>
                                            </CardBody>
                                        </Card>
                                        <Card className="table-section mb-3">
                                            <CardHeader>
                                                <span className="practice-heading">
                                                    {'Donation History'}
                                                </span>
                                            </CardHeader>
                                            <CardBody>
                                                <DonationHistory
                                                    userId={props.userID}
                                                    selectedTransactionId={
                                                        selectedTransactionId
                                                    }
                                                    onTransactionIdClick={
                                                        setSelectedTransactionId
                                                    }
                                                    type="UsersDonationHistory"></DonationHistory>
                                            </CardBody>
                                        </Card>
                                    </>
                                )}
                            </TabPane>
                            <TabPane tabId="8">
                                {changeLogData.length > 0 ? (
                                    <ChatExample
                                        data={changeLogData}></ChatExample>
                                ) : (
                                    <div className="empty-msg">
                                        No Data found
                                    </div>
                                )}
                            </TabPane>
                            <TabPane tabId="9">
                                <Form>
                                    <FormGroup row>
                                        <Label for="name" sm={4}>
                                            To reset user's Password:{' '}
                                        </Label>
                                        <Col sm={8}>
                                            <Button
                                                onClick={forgotPasswordHandler}
                                                size="large"
                                                color="success">
                                                Submit
                                            </Button>
                                        </Col>
                                    </FormGroup>

                                    <FormGroup row>
                                        <Label for="name" sm={4}>
                                            To delete user:{' '}
                                        </Label>
                                        <Col sm={8}>
                                            disabled
                                            {/*<Button
                                                onClick={deleteUserHandler}
                                                size="large"
                                                color="success">
                                                Click here
                                            </Button>*/}
                                        </Col>
                                    </FormGroup>

                                    <FormGroup row>
                                        <Label for="name" sm={4}>
                                            To block user:{' '}
                                        </Label>
                                        <Col sm={8}>
                                            <Button
                                                onClick={blockUserHandler}
                                                size="large"
                                                color="success">
                                                Click here
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                    {userData.roleId != 2 && (
                                        <FormGroup row>
                                            <Label for="name" sm={4}>
                                                To{' '}
                                                {userData.isSuspended
                                                    ? 'unsuspend'
                                                    : 'suspend'}{' '}
                                                user:{' '}
                                            </Label>
                                            <Col sm={8}>
                                                <Button
                                                    onClick={() => {
                                                        suspendUser();
                                                    }}
                                                    size="large"
                                                    color="success">
                                                    {userData.isSuspended
                                                        ? 'Unsuspend'
                                                        : 'Suspend'}
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                    )}
                                </Form>
                            </TabPane>
                            <TabPane tabId="10">
                                <Form>
                                    <div className="w9-approval">
                                        <W9HistoryModal
                                            isVisisble={true}
                                            isTableMode={true}
                                            name={userData.name}
                                            userId={props.userID}
                                            userDocList={userDocList}
                                            docListColumns={
                                                docListColumns
                                            }></W9HistoryModal>
                                    </div>
                                </Form>
                            </TabPane>
                        </TabContent>
                    )}
                </ModalBody>
                <ModalFooter></ModalFooter>
            </Rodal>

            {rowToDelete && (
                <ConfirmationModal
                    isVisible={true}
                    setIsVisible={() => cancelDelete()}
                    header={`Delete User: ${rowToDelete.name}`}
                    confirmationText={
                        'This action may not be reversible. Please use caution when deleting any user. Do you really want to delete?'
                    }
                    onConfirm={() => disableUser(rowToDelete)}
                    nextButtonText={'Yes, Delete User'}
                    closeOnConfirm={false}
                    cancelButtonText={'No'}
                    isLoading={isDeleting}
                    isConfirmationDanger={true}
                />
            )}

            {rowToBlock && (
                <CustomModal
                    setVisible={cancelBlock}
                    title={'Block User'}
                    modalSize={MODAL_SIZE.MEDIUM}
                    visible={true}
                    buttons={[
                        {
                            title: 'No',
                            onClick: cancelBlock,
                            color: 'primary',
                            isEnabled: true,
                        },
                        {
                            title: 'Yes, Block User',
                            onClick: () => blockUser(rowToBlock),
                            color: 'danger',
                            isEnabled: true,
                        },
                    ]}>
                    <Label>Do you really want to block?</Label>
                </CustomModal>
            )}

            {isConfirmationDialogVisible && (
                <ConfirmationModal
                    header={`Confirmation`}
                    isVisible={isConfirmationDialogVisible}
                    setIsVisible={setIsConfirmationDialogVisible}
                    confirmationText={`Are you sure you want to make this practice Emeritus?`}
                    params={undefined}
                    onConfirm={() => {
                        convertPracticeToFormer();
                    }}
                    nextButtonText="Yes, I'm sure"
                    cancelButtonText="No, take me back"></ConfirmationModal>
            )}
        </Fragment>
    );
};
export default UserDetailsModal;
