import React, { useEffect, useRef, useState } from 'react';
import './DataFormTemplateEditorHost.scss';
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardBody,
    Col,
    Label,
    Row,
} from 'reactstrap';
import { HomeButton } from '../../../../components/HomeButton/home-button';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Field, Form } from '@availity/form';
import * as yup from 'yup';
import { useStateSelector } from '../../../../store/selectors';
import createNotification from '../../../../utils/createNotification';
import DataFormTemplate from '../../../../models/DataRequestHub/DataFormTemplate';
import {
    DataFormType,
    DataFormTypeList,
} from '../../../../models/DataRequestHub/DataFormTypeEnum';
import DataFormQuestion from '../../../../models/DataRequestHub/DataFormQuestion';
import ButtonLoader from '../../../../components/Layout/Buttons/ButtonLoader';
import { EditProjectVariablePopup } from '../Popups/EditProjectVariablePopup/EditProjectVariablePopup';
import {
    ProjectVariable,
    ProjectVariableOption,
    SystemVariableNames,
    SystemVariableNamesList,
} from '../../../../models/DataRequestHub/ProjectVariable';
import DataRequestProjectNameEditor from '../DataRequestProjectNameEditor/DataRequestProjectNameEditor';
import DocumentTemplateEditor from './DocumentTemplateEditor';
import FinancialRequestTemplateEditor from './FinancialRequestTemplateEditor';
import { TableSettingsPopup } from '../Popups/TableSettingsPopup/TableSettingsPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faL, faWrench } from '@fortawesome/free-solid-svg-icons';
import DataLoader from '../../../../components/DataLoader/DataLoader';
import { ProjectVariableHost } from '../DataAndDocumentRequestsDashboard/ProjectVariableHost/ProjectVariableHost';
import QuestionnaireTemplateEditorHost from './QuestionnaireTemplateEditorHost';
import {
    DataRequestProject,
    DataRequestProjectState,
    emptyProject,
} from '../../../../models/DataRequestHub/DataRequestProject';
import {
    DataForm,
    emptyDataForm,
} from '../../../../models/DataRequestHub/DataForm';
import { DesignModeSwitcher } from '../../../DataRequest/DesignModeSwitcher/DesignModeSwitcher';

export enum TemplateFormModeEnum {
    CreateTemplate,
    EditTempalte,
    EditProject,
}

export const DataFormTemplateEditorHost = () => {
    const axios = useStateSelector((s) => s.core.axios);
    const gridTableRef = useRef(null);
    const [project, setProject] = useState<DataRequestProject>(emptyProject);
    const [mode, setMode] = useState<TemplateFormModeEnum>(
        TemplateFormModeEnum.CreateTemplate
    );
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [
        isProjectVariableEditPopupVisible,
        setIsProjectVariableEditPopupVisible,
    ] = useState(false);
    const [isTableSettingsPopupVisible, setIsTableSettingsPopupVisible] =
        useState(false);
    const [variableNameToEdit, setVariableNameToEdit] = useState('');
    const [varialbeIdToEdit, setVarialbeIdToEdit] = useState(0);
    const [likelySourceVariable, setLikelySourceVariable] =
        useState<ProjectVariable>({ id: 0, name: '', options: [] });
    const [answerTypeVariable, setAnswerTypeVariable] =
        useState<ProjectVariable>({ id: 0, name: '', options: [] });
    const [priorityVariable, setPriorityVariable] = useState<ProjectVariable>({
        id: 0,
        name: '',
        options: [],
    });
    const [formTypeVariable, setFormTypeVariable] = useState<ProjectVariable>({
        id: 0,
        name: '',
        options: [],
    });
    const [numericFormatVariable, setNumericFormatVariable] =
        useState<ProjectVariable>({ id: 0, name: '', options: [] });
    const [textSizeVariable, setTextSizeVariable] = useState<ProjectVariable>({
        id: 0,
        name: '',
        options: [],
    });
    const [notSystemVariables, setNotSystemVariables] = useState<
        ProjectVariable[]
    >([
        {
            id: 0,
            name: '',
            options: [],
        },
    ]);

    const [displayFormatVariable, setDisplayFormatVariable] =
        useState<ProjectVariable>({ id: 0, name: '', options: [] });
    const [frequency, setFrequency] = useState<ProjectVariable>({
        id: 0,
        name: '',
        options: [],
    });
    const [projectVariableOptionsToEdit, setProjectVariableOptionsToEdit] =
        useState<Array<ProjectVariableOption>>([]);
    const templateRef = useRef(null);
    const location = useLocation();
    const [currentTab, setCurrentTab] = useState<number>();
    const [additionalUrlToReturn, setAdditionalUrlToReturn] = useState('');
    const [tabTemplateName, setTabTemplateName] = useState('');
    const [searchParams] = useSearchParams({});
    const variablesTabIndex = 3;
    const [tabData] = useState([
        'Documents',
        'Financial Requests',
        'Questionnaire',
        'Variables',
    ]);
    const [tabsHasErrorState, setTabsHasErrorState] = useState<any>({});
    const [shouldNavigateToProjectView, setShouldNavigateToProjectView] =
        useState<boolean>(false);

    useEffect(() => {
        let formType = DataFormType.Documents;
        if (location.state) {
            formType = location.state.type as DataFormType;
            setCurrentTab(formType ?? DataFormType.Documents);

            const additionalUrl = location.state
                .additionalUrlToReturn as string;
            setAdditionalUrlToReturn(additionalUrl);

            const shouldNavigateToProjectViewMode =
                location.state.shouldNavigateToProjectViewMode ?? false;
            setShouldNavigateToProjectView(shouldNavigateToProjectViewMode);
            const mode = location.state.mode as TemplateFormModeEnum;
            setMode(mode);
            let project = location.state.project as DataRequestProject;
            const dataForms = location.state.dataForms as DataForm[];

            if (!project) {
                const defaultProject: DataRequestProject = {
                    ...project,
                    dataForms: dataForms ?? [
                        { ...emptyDataForm, formType: formType },
                    ],
                };
                project = defaultProject;
            }

            project = {
                ...project,
                dataForms: project.dataForms,
            };
            setProject(project);
            fetchProjectVariables(project?.id ?? 0);
        }

        const value = searchParams.get('tab');
        const numberValue = Number(value);
        if (numberValue && numberValue >= 1 && numberValue <= 3) {
            const index = numberValue - 1;
            setCurrentTab(index);
        } else {
            setCurrentTab(formType);
        }
    }, []);

    useEffect(() => {
        let templateName = '';

        switch (currentTab) {
            case DataFormType.Documents:
                templateName = project.dataForms?.find(
                    (x) => x.formType === DataFormType.Documents
                )?.originalTemplateName;
                break;
            case DataFormType.FinancialRequest:
                templateName = project.dataForms?.find(
                    (x) => x.formType === DataFormType.FinancialRequest
                )?.originalTemplateName;
                break;
        }

        setTabTemplateName(templateName);
    }, [currentTab]);

    const fetchProjectVariables = (projectId: number) => {
        setIsLoading(true);
        axios
            .get(`/api/DataRequestProject/${projectId}/ProjectVariables`)
            .then((response) => {
                if (response.status === 200) {
                    const variables = response.data as Array<ProjectVariable>;

                    const likelySourceVariable = variables.find(
                        (x) =>
                            x.name ==
                            SystemVariableNamesList.find(
                                (y) => y.id == SystemVariableNames.LikelySource
                            ).name
                    );

                    const displayFormatVariable = variables.find(
                        (x) =>
                            x.name ==
                            SystemVariableNamesList.find(
                                (y) => y.id == SystemVariableNames.DisplayFormat
                            ).name
                    );

                    const frequencyVariable = variables.find(
                        (x) =>
                            x.name ==
                            SystemVariableNamesList.find(
                                (y) => y.id == SystemVariableNames.Frequency
                            ).name
                    );

                    const answerTypeVariable = variables.find(
                        (x) =>
                            x.name ==
                            SystemVariableNamesList.find(
                                (y) => y.id == SystemVariableNames.AnswerType
                            ).name
                    );

                    const priorityVariable = variables.find(
                        (x) =>
                            x.name ==
                            SystemVariableNamesList.find(
                                (y) => y.id == SystemVariableNames.Priority
                            ).name
                    );

                    const textSizeVariable = variables.find(
                        (x) =>
                            x.name ==
                            SystemVariableNamesList.find(
                                (y) => y.id == SystemVariableNames.TextSize
                            ).name
                    );

                    const formTypeVariable = variables.find(
                        (x) =>
                            x.name ==
                            SystemVariableNamesList.find(
                                (y) => y.id == SystemVariableNames.FormType
                            ).name
                    );

                    const numericFormatVariable = variables.find(
                        (x) =>
                            x.name ==
                            SystemVariableNamesList.find(
                                (y) => y.id == SystemVariableNames.NumericFormat
                            ).name
                    );

                    const notSystemVariables = variables.filter(
                        (x) =>
                            !SystemVariableNamesList.some(
                                (y) => x.name === y.name
                            )
                    );

                    setLikelySourceVariable(likelySourceVariable);
                    setAnswerTypeVariable(answerTypeVariable);
                    setPriorityVariable(priorityVariable);
                    setFormTypeVariable(formTypeVariable);
                    setNumericFormatVariable(numericFormatVariable);
                    setTextSizeVariable(textSizeVariable);
                    setDisplayFormatVariable(displayFormatVariable);
                    setFrequency(frequencyVariable);
                    setNotSystemVariables(notSystemVariables);
                } else {
                    createNotification(
                        'An error occured while fetching project variables',
                        'error'
                    );
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getHeaderTitle = () => {
        var typeName =
            DataFormTypeList.find((c) => c.id == currentTab)?.name ?? '';
        switch (mode) {
            case TemplateFormModeEnum.CreateTemplate:
                return `Add New ${typeName} Template`;
            case TemplateFormModeEnum.EditTempalte:
                return `Edit ${typeName} Template`;
            case TemplateFormModeEnum.EditProject:
                return (
                    <div className="name-editor-container">
                        <DataRequestProjectNameEditor
                            projectName={project.name}
                            projectId={project.id}
                            onSuccessfulUpdate={(value) =>
                                setProject({ ...project, name: value })
                            }
                        />
                        <div>{` - Practice: ${project.practiceName}`}</div>
                    </div>
                );
            default:
                break;
        }
    };

    const getBreadcrumbItem = () => {
        var typeName =
            DataFormTypeList.find((c) => c.id == currentTab)?.name ?? '';
        switch (mode) {
            case TemplateFormModeEnum.CreateTemplate:
                return `Add New ${typeName} Template`;
            case TemplateFormModeEnum.EditTempalte:
                return `Edit ${typeName} Template`;
            case TemplateFormModeEnum.EditProject:
                return `${project.name} - Practice: ${project.practiceName}`;
            default:
                break;
        }
    };

    const getTemplateNameField = () => {
        if (mode === TemplateFormModeEnum.EditProject) {
            return (
                <>
                    <Label>
                        <strong>Original Template Name:</strong>{' '}
                        {tabTemplateName}
                    </Label>
                </>
            );
        }

        const currentForm = getCurrentTemplateForm();

        return (
            <>
                <Label>
                    <strong>Template Name:</strong>
                </Label>
                <Field
                    name="templateName"
                    value={currentForm?.originalTemplateName}
                    onChange={(event: any) => {
                        const state: DataForm = {
                            ...currentForm,
                            originalTemplateName: event.target.value,
                            formType: currentTab,
                            isVisible: true,
                        };
                        updateForm(state);
                    }}></Field>
            </>
        );
    };

    const hasAnyFormError = () =>
        Object.entries(tabsHasErrorState).some((formState) => {
            const [dataFormType, hasError] = formState;

            if (Number(dataFormType) === currentTab) {
                return false;
            }

            return hasError;
        });

    const getCurrentTemplateForm = () =>
        project.dataForms.find((form) => form.formType === currentTab);

    const onSuccessfulSubmit = (templateName: string) => {
        let isRowsValid = true;
        if (currentTab !== variablesTabIndex) {
            isRowsValid = gridTableRef?.current.validateAllRows();
            setTabsHasErrorState({
                ...tabsHasErrorState,
                [currentTab]: !isRowsValid,
            });
        }

        if (!isRowsValid || hasAnyFormError()) {
            return;
        }

        switch (mode) {
            case TemplateFormModeEnum.CreateTemplate:
                createTemplate(templateName);
                break;
            case TemplateFormModeEnum.EditTempalte:
                updateTemplate();
                break;
            case TemplateFormModeEnum.EditProject:
                updateProject();
                break;
            default:
                break;
        }
    };

    const createTemplate = (templateName: string) => {
        setIsLoading(true);

        const templateQuestions = getCurrentTemplateForm().questions;

        axios
            .post('/api/dataRequestTemplateProject/dataForm', {
                originalTemplateName: templateName,
                formType: currentTab,
                questions: templateQuestions,
            } as DataFormTemplate)
            .then((response) => {
                if (response.status === 200) {
                    navigate(
                        `/data-and-document-templates-dashboard${additionalUrlToReturn}`
                    );
                } else {
                    createNotification(
                        'An error occured while creating template',
                        'error'
                    );
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const updateTemplate = () => {
        setIsLoading(true);

        const templateForm = getCurrentTemplateForm();

        axios
            .put('/api/dataRequestTemplateProject/dataForm', {
                id: templateForm.id,
                originalTemplateName: templateForm.originalTemplateName,
                formType: currentTab,
                questions: templateForm.questions,
            } as DataFormTemplate)
            .then((response) => {
                if (response.status === 200) {
                    navigate(
                        `/data-and-document-templates-dashboard${additionalUrlToReturn}`
                    );
                } else {
                    createNotification(
                        'An error occured while updating template',
                        'error'
                    );
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const updateProject = () => {
        setIsLoading(true);
        const params = project.dataForms;

        axios
            .put(`/api/DataRequestProjects/${project.id}`, params)
            .then((response) => {
                if (response.status === 200) {
                    if (shouldNavigateToProjectView) {
                        navigateToProjectViewMode();
                    } else {
                        navigate('/data-and-document-requests-dashboard');
                    }
                } else {
                    createNotification(
                        'An error occured while updating template',
                        'error'
                    );
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const updateDocumentTemplateRows = (
        rows: DataFormQuestion[],
        currentType: DataFormType
    ) => {
        const updatedDataForms = [...project.dataForms];
        const updatedForm = project.dataForms.find(
            (form) => form.formType === currentType
        );

        const formIndex = project.dataForms.indexOf(updatedForm);

        updatedForm.questions = rows;
        updatedDataForms[formIndex] = updatedForm;

        const updatedProject = {
            ...project,
            dataForms: updatedDataForms,
        };

        setProject(updatedProject);
    };

    const updateForm = (newForm: DataForm) => {
        const updatedDataForms = [...project.dataForms];
        const targetForm = updatedDataForms.find(
            (form) => form.id === newForm.id
        );
        const targetIndex = updatedDataForms.indexOf(targetForm);

        updatedDataForms[targetIndex] = newForm;

        setProject({ ...project, dataForms: updatedDataForms });
    };

    const updateMultipleForms = (updatedForms: DataForm[]) =>
        updatedForms.forEach((form) => updateForm(form));

    const getTemplateRows = (formType: DataFormType) => [
        ...(project.dataForms.find((form) => form.formType === formType)
            ?.questions ?? []),
    ];

    const getTemplateForms = (formType: DataFormType) => [
        ...project.dataForms.filter((form) => form.formType === formType),
    ];

    const renderEditor = () => {
        switch (currentTab) {
            case DataFormType.Documents:
                return (
                    <DocumentTemplateEditor
                        setIsProjectVariableEditPopupVisible={
                            setIsProjectVariableEditPopupVisible
                        }
                        setVarialbeIdToEdit={setVarialbeIdToEdit}
                        setVariableNameToEdit={setVariableNameToEdit}
                        templateRows={getTemplateRows(DataFormType.Documents)}
                        setTemplateRows={(rows) => {
                            updateDocumentTemplateRows(
                                rows,
                                DataFormType.Documents
                            );
                        }}
                        setProjectVariableOptionsToEdit={
                            setProjectVariableOptionsToEdit
                        }
                        isLoading={isLoading}
                        likelySourceVariable={likelySourceVariable}
                        displayFormatVariable={displayFormatVariable}
                        ref={gridTableRef}
                    />
                );
            case DataFormType.FinancialRequest:
                return (
                    <FinancialRequestTemplateEditor
                        setIsProjectVariableEditPopupVisible={
                            setIsProjectVariableEditPopupVisible
                        }
                        setVarialbeIdToEdit={setVarialbeIdToEdit}
                        setVariableNameToEdit={setVariableNameToEdit}
                        templateRows={getTemplateRows(
                            DataFormType.FinancialRequest
                        )}
                        setTemplateRows={(rows) => {
                            updateDocumentTemplateRows(
                                rows,
                                DataFormType.FinancialRequest
                            );
                        }}
                        setProjectVariableOptionsToEdit={
                            setProjectVariableOptionsToEdit
                        }
                        isLoading={isLoading}
                        likelySourceVariable={likelySourceVariable}
                        displayFormatVariable={displayFormatVariable}
                        frequencyVariable={frequency}
                        ref={gridTableRef}
                    />
                );
            case DataFormType.Questionnaire:
                return (
                    <QuestionnaireTemplateEditorHost
                        editMode={mode}
                        isAdminView={true}
                        templateForms={getTemplateForms(
                            DataFormType.Questionnaire
                        )}
                        setTemplateForms={updateMultipleForms}
                        notSystemVariables={notSystemVariables}
                        isLoading={isLoading}
                        answerType={answerTypeVariable}
                        priority={priorityVariable}
                        formType={formTypeVariable}
                        numericFormat={numericFormatVariable}
                        textSize={textSizeVariable}
                        ref={gridTableRef}
                        project={project}
                        updateProjectData={fetchUpdatedDataForms}
                        updateProjectVariables={() =>
                            fetchProjectVariables(project.id)
                        }
                    />
                );
            case variablesTabIndex:
                return (
                    <ProjectVariableHost
                        onUpdate={() => {
                            fetchProjectVariables(project.id);
                        }}
                        projectId={project.id}></ProjectVariableHost>
                );
            default:
                return <DataLoader />;
        }
    };

    const changeTab = (index: number) => {
        if (currentTab !== variablesTabIndex) {
            const isRowsValid = gridTableRef?.current.validateAllRows();
            setTabsHasErrorState({
                ...tabsHasErrorState,
                [currentTab]: !isRowsValid,
            });
        }
        const tabNumber = index + 1;

        navigate(`/document-template-form?tab=${tabNumber}`, {
            state: {
                project: project,
                mode: mode,
                type: currentTab,
            },
        });

        setCurrentTab(index);
    };

    const getTabClasses = (index: number) => {
        let tabClasses = 'RRT__tab';

        if (
            !project.dataForms.some((x) => x.formType == index) &&
            variablesTabIndex !== index
        ) {
            return `${tabClasses} d-none`;
        }

        if (currentTab == index) {
            return `${tabClasses} RRT__tab--first RRT__tab--selected`;
        }

        if (tabsHasErrorState && tabsHasErrorState[index]) {
            return `${tabClasses} error-tab`;
        }
        return tabClasses;
    };

    const renderTemplateNameForm = () => {
        if (project.dataForms.length) {
            return (
                <div className="editor-header">
                    <Form
                        innerRef={templateRef}
                        initialValues={{
                            templateName:
                                getCurrentTemplateForm()?.originalTemplateName,
                        }}
                        validationSchema={yup.object().shape({
                            templateName: yup.string().required().max(100),
                        })}
                        onSubmit={(fields) => {
                            onSuccessfulSubmit(fields.templateName);
                        }}>
                        <Col>
                            <Row className="header-row">
                                {getTemplateNameField()}
                                <Button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={() => {
                                        gridTableRef?.current.addRow();
                                    }}>
                                    + Add New Row
                                </Button>
                            </Row>
                        </Col>
                    </Form>
                    {mode === TemplateFormModeEnum.EditProject && (
                        <div className="project-settings-section">
                            <div
                                className="clickable"
                                onClick={() => {
                                    setIsTableSettingsPopupVisible(true);
                                }}>
                                <FontAwesomeIcon icon={faWrench} /> Settings
                            </div>
                            {project.state ===
                                DataRequestProjectState.Active && (
                                <DesignModeSwitcher
                                    formId={0}
                                    defaultValue={true}
                                    onDisable={() => {
                                        navigateToProjectViewMode();
                                    }}></DesignModeSwitcher>
                            )}
                        </div>
                    )}
                </div>
            );
        }
    };

    const navigateToProjectViewMode = () => {
        navigate(`/data-request-dashboard?tab=${currentTab + 1}`, {
            state: {
                projectId: project.id,
                isAdmin: true,
                projectName: project.name,
                practiceName: project.practiceName,
            },
        });
    };

    const fetchUpdatedDataForms = () => {
        axios
            .get(`api/DataRequestProjects/${project.id}`)
            .then((response) => {
                if (response?.status === 200) {
                    const newForms = response.data.dataForms as DataForm[];
                    let updatedDataForms = newForms.map((form) => {
                        const editableForm = project.dataForms.find(
                            (f) => f.id === form.id
                        );

                        return {
                            ...form,
                            questions: editableForm
                                ? editableForm.questions
                                : form.questions,
                            name: form.originalTemplateName,
                        };
                    });

                    const updatedProject: DataRequestProject = {
                        ...project,
                        dataForms: updatedDataForms,
                    };
                    
                    setProject(updatedProject);
                } else {
                    createNotification(
                        'Error occured while fetching project',
                        'error'
                    );
                }
            })
            .catch(() => {
                createNotification(
                    'Error occured while fetching project',
                    'error'
                );
            });
    };

    const isTemplateFormNameVisible = () => {
        const result =
            currentTab !== variablesTabIndex &&
            !(
                currentTab === DataFormType.Questionnaire &&
                mode === TemplateFormModeEnum.EditProject
            );
        return result;
    };

    return (
        <div className="create-edit-document-template">
            <div className="title-content">
                <div className="page-title">
                    <h3>{getHeaderTitle()}</h3>
                </div>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <HomeButton></HomeButton>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                        {getBreadcrumbItem()}
                    </BreadcrumbItem>
                </Breadcrumb>
            </div>
            <Card className="main-card">
                <CardBody className="card-body-custom">
                    {mode === TemplateFormModeEnum.EditProject ? (
                        <>
                            <div className="RRT__container">
                                <div className="RRT__tabs body-tabs">
                                    {tabData?.map((item, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={getTabClasses(index)}
                                                onClick={() => {
                                                    changeTab(index);
                                                }}
                                                id={'tab-info-' + index}>
                                                {item}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                    {isTemplateFormNameVisible()
                        ? renderTemplateNameForm()
                        : []}
                    {renderEditor()}
                    <Col>
                        <Row className="buttons-wrapper">
                            <Button
                                type="button"
                                className="btn btn-cancel"
                                disabled={isLoading}
                                onClick={() => {
                                    if (
                                        mode ===
                                        TemplateFormModeEnum.EditProject
                                    ) {
                                        if (shouldNavigateToProjectView) {
                                            navigateToProjectViewMode();
                                        } else {
                                            navigate(
                                                '/data-and-document-requests-dashboard'
                                            );
                                        }
                                    } else {
                                        navigate(
                                            `/data-and-document-templates-dashboard${additionalUrlToReturn}`
                                        );
                                    }
                                }}>
                                Cancel & Close
                            </Button>
                            <ButtonLoader
                                type={'button'}
                                buttonText={'Save & Close'}
                                loaderButtonText={''}
                                disabled={false}
                                isLoading={isLoading}
                                className="btn btn-primary"
                                onClick={() => {
                                    if (
                                        currentTab === variablesTabIndex ||
                                        (mode ===
                                            TemplateFormModeEnum.EditProject &&
                                            currentTab ===
                                                DataFormType.Questionnaire)
                                    ) {
                                        onSuccessfulSubmit('');
                                    } else {
                                        templateRef?.current.handleSubmit();
                                    }
                                }}
                            />
                        </Row>
                    </Col>
                </CardBody>
            </Card>
            {isProjectVariableEditPopupVisible && (
                <EditProjectVariablePopup
                    projectId={project?.id ?? 0}
                    options={projectVariableOptionsToEdit}
                    variableId={varialbeIdToEdit}
                    onSubmit={() => {
                        fetchProjectVariables(project?.id ?? 0);
                        setIsProjectVariableEditPopupVisible(false);
                    }}
                    variableName={variableNameToEdit}
                    close={() =>
                        setIsProjectVariableEditPopupVisible(false)
                    }></EditProjectVariablePopup>
            )}
            {isTableSettingsPopupVisible && (
                <TableSettingsPopup
                    isVisible={isTableSettingsPopupVisible}
                    setIsVisible={setIsTableSettingsPopupVisible}
                    onSubmit={() => {
                        fetchUpdatedDataForms();
                        fetchProjectVariables(project?.id ?? 0);
                    }}
                    projectId={project?.id ?? 0}
                    dataForms={project.dataForms}></TableSettingsPopup>
            )}
        </div>
    );
};
